$auburn: #741F2F;

#form {
    border: .65em solid $auburn;
    padding: 1em 1em;
    min-height: 20rem;

    & > div, & > label {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1.5em;

        & > :first-child {
            margin-bottom: .5em;
        }
    }

    input[type=text], textarea {
        border: 1px solid $auburn;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        padding: .5em 1em;
        width: 100%;
    }

    textarea {
        height: 6.5em;
        margin-bottom: 0;
        resize: none;
    }

    input[type=submit] {
        border: 2px solid $auburn;
        background-color: $auburn;
        color: #fff;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        padding: .5em 1em;;

        &:hover {
            background-color: #fff;
            color: $auburn;
        }
    }
    
    #radio {
        div {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        input[type=radio] {
            margin: 0 .5em;
            vertical-align: middle;
        }
    } 

    .additional {
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    #confirmation {
        margin: 1.5em 0;
        text-align: center;
    }
}

#accompanying  {
    width: 100%;
}

@media only screen and (min-width: 800px) {
    #form {
        & > div, & > label {
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 900px;

            & > :first-child {
                margin-bottom: 0;
            }
        }
        
        input[type=text], textarea {
            width: 450px;
        }

        #radio {
            div {
                width: 450px;
            }
        }

        #comments {
            span {
                margin-bottom: .5em;
            }

            textarea {
                width: 100%;
            }
        }
    }

    #accompanying-label {
        align-self: flex-start;
    }

    #accompanying {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}