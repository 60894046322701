$auburn: #741F2F;
$yellow: #FFCC01;
$grey: #D1CEC5;
$dark-grey: #997C67;
$brown: #755330;
$auburn-alt: #B0703C;
$orange: #DBA72E;
$pinkish: #E3CCA1;

* {
    font-family: caudex, serif, Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 0;
    transition: .2s;
}

a {
    color: $orange;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.center {
    text-align: center;
}

.section {
    align-items: center;
    background-color: white;
    box-shadow: 0 8px 16px 24px rgba(16,31,47,0.5);
    display: flex;
    flex-wrap: wrap;

    div {
        & > * {
            &:first-child {
                margin-top: .5em;
            }

            &:last-child {
                margin-bottom: 1em;
            }
        }

        h3 {
            margin-top: 1em;
        }

        i {
            margin: 0;
        }
    }

    img {
        width: 100%;
    }

    & > * {
        flex-grow: 100%;
    }
}

.paralax {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    height: 87vh;
}

.parasm {
    height: 75vh;
}

#bg1 {
    background-image: url(./imgs//ring.jpg);
}

#bg2 {
    background-image: url(./imgs/sunset.jpg);
}

.bottomless-shadow {
    box-shadow: 0 -32px 32px 0 rgba(16,31,47,0.5);
}

#header {
    background: rgba(255, 255, 255, .4);
    box-shadow: 0 8px 16px 0 rgba(16,31,47,0.5);
    color: #741F2F;
    font-size: 1rem;
    margin: 0 .5em;
    padding: .5em;
    position: relative;
    top: 3vh;

    div {
        border: .25em solid #741F2F;
    }
}

.w-100 {
    width: 100%;
}

@media only screen and (min-width: 800px) {
    .section {
        div {
            & > * {
                margin: 0 4em;

                &:first-child {
                    margin-top: 1em;
                }

                &:last-child {
                    margin-bottom: 1em;
                }
            }

            i {
                margin: 0;
            }
        }

        img {
            width: 50%;
        }

        div,img {
            flex: 0 0 50%;
        }
    }
}